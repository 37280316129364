
import { defineComponent, ref, unref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useMessage } from '@/hooks/core/useMessage'
export default defineComponent({
  name: 'LoginForm',
  props: {
    type: Number
  },
  setup (props) {
    const Message = useMessage()
    const store = useStore()
    const router = useRouter()
    const formRef = ref<any>(null)
    const formData = reactive({
      account: '',
      password: '',
      isLoading: false,
      type: props.type
    })
    const rules = reactive({
      account: [
        { required: true, message: '手机号/邮箱不能为空', trigger: 'blur' }
      ],
      password: [
        { required: true, message: '密码不能为空', trigger: 'blur' }
      ]
    })
    const loginBtnText = computed(() => {
      const { type, isLoading } = formData
      const txt = type === 0 ? '登录' : '注册'
      return isLoading ? `${txt}中...` : txt
    })

    const handleSubmitClick = () => {
      const form = unref(formRef)
      if (!form) {
        return
      }
      form.validate(async (valid: boolean) => {
        if (!valid) {
          return
        }
        formData.isLoading = true
        try {
          const loginParams = {
            account: formData.account,
            password: formData.password
          }
          const dispatchAction = formData.type === 0 ? 'user/login' : 'user/register'
          const messageType = formData.type === 0 ? '登录' : '注册'
          const submitResult = await store.dispatch(dispatchAction, loginParams)
          if (submitResult) {
            Message({
              type: 'success',
              message: `${messageType}成功`,
              duration: 1500,
              onClose: () => {
                router.push('/')
              }
            })
          }
        } catch (e) {
          formData.password = ''
          Message.error(e.message || '登录失败')
        } finally {
          formData.isLoading = false
        }
      })
    }
    return {
      formRef,
      formData,
      rules,
      loginBtnText,
      handleSubmitClick
    }
  }
})
